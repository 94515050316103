import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Do I Need A Database Administrator?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "If your company’s database contains personal and financial information, it is important to secure it. Codestaff database administrators will back up your systems in case of a power outage or any disaster. They will also ensure the integrity of your database and guarantee the data stored comes from reliable sources."
        }
    }
}

const DatabaseAdministrator = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Database Administrators.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Database Administrators. Top
                                    companies and start-ups choose Codestaff professional Database
                                    Administrators for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Database Administrator now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE DATABASE ADMINISTRATORS' banner='/database-administrator.png' bannerAlt='database administrator banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default DatabaseAdministrator